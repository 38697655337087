import React from 'react';
import '../../App.css';
import HeroSection from '../HeroSection';
// import Footer from '../Footer';


function Home(){
    return (
        <>
            <HeroSection />
        </>
    );
}

export default Home;